import React, {useState} from 'react';
import {Spinner} from 'reactstrap';

const LoginTwoFa = (props: { validateTwoFaStatus: (userName: string, password: string) => Promise<{configured: boolean, image: string}>, authenticate: (partialBody: object, platform: string) => void }) => {
    const [userName, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<'login' | 'qr-code' | 'token'>('login');
    const [qrCode, setQrCode] = useState<string>('');

    const checkTwoFaStatus = async (e: any) => {
        e.preventDefault();
        try {
            let validation = await props.validateTwoFaStatus(userName, password);

            if (!validation.configured) {
                setPage('qr-code');
                setQrCode(validation.image);
            } else {
                setPage('token');
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const signIn = (e: any) => {
        e.preventDefault();

        props.authenticate({
            username: userName,
            password: password,
            totpCode: token,
        }, 'twoFactor');
    };

    return (
        <div>
            {page === 'login' &&
                <>
                    {loading && <Spinner size="lg"/>}
                    {!loading &&
                        <form onSubmit={checkTwoFaStatus}>
                            <input type="text" className="form-control mb-3" placeholder="Username"
                                   value={userName}
                                   onChange={event => setUsername(event.target.value)} required autoFocus/>
                            <input type="password" className="form-control mb-3" placeholder="Password"
                                   value={password}
                                   onChange={event => setPassword(event.target.value)} required/>
                            <input type="submit" className="btn btn-lg btn-success btn-block"
                                   value="Sign in with credentials"/>
                        </form>
                    }
                </>
            }

            {page === 'token' &&
                <div>
                    <form onSubmit={signIn}>
                        <input type="number" className="form-control mb-3" placeholder="Token"
                               value={token}
                               onChange={event => setToken(event.target.value)} required/>

                        <input type="submit" className="btn btn-lg btn-success btn-block" value="Sign in"/>
                    </form>
                </div>
            }

            {page === 'qr-code' &&
                <div>
                    <form onSubmit={signIn}>
                        <div>
                            <div style={{marginBottom: 10}}>
                                The backoffice uses 2-factor authentication to protect your data. Therefore, scan the QR
                                code below with the Authenticator app on your phone and click 'Continue'. Then enter the
                                verification code generated by your Authenticator app.
                            </div>

                            <img src={qrCode} alt=""/>

                            <input type="number" className="form-control mb-3" placeholder="Verification code"
                                   value={token}
                                   onChange={event => setToken(event.target.value)} required/>
                        </div>

                        <input type="submit" className="btn btn-lg btn-success btn-block" value="Sign in"/>
                    </form>
                </div>
            }
        </div>
    );
};

export default LoginTwoFa;
