import React from 'react';
import {Row, Col} from 'reactstrap';
import Field from "../Elements/Field";
import Table from "../Elements/Table/Table";
import {Link} from "react-router-dom";
import {Button} from 'reactstrap';
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import {PencilIcon, PlusIcon} from "react-octicons";
import TableJSON from "../Elements/Table/TableJSON";
import {downloadBuckarooFile} from "../services";
import prettyBytes from "pretty-bytes";

const SenderOnboardingDetail = ({
                                    id,
                                    name,
                                    iban,
                                    vatNumber,
                                    address,
                                    email,
                                    language,
                                    paylink,
                                    paylinkDocumentId,
                                    paylinkStatus,
                                    paylinkDate,
                                    paylinkIban,
                                    requestId,
                                    settings,
                                    onboardingPartner,
                                    dateCreated,
                                    connectLink,
                                    contactPerson,
                                    buckarooOnboardingAPIData,
                                    buckarooOnboardingReference,
                                    buckarooDocumentsToComplete,
                                    buckarooRequests,
                                    buckarooFiles,
                                    buckarooFinalized,
                                }) => {
    const settingsFormatted = Object.keys(settings).map(key => ({
        key: key,
        value: typeof settings[key] === 'string' ? settings[key] : JSON.stringify(settings[key]),
    }));

    return (
        <>
            <Row form>
                <Col xs="auto">
                    <h1 className="mb-5">
                        Sender Onboarding: {requestId}
                    </h1>
                </Col>
                <Col style={{marginTop: '12px'}}>
                    <LinkToOverlay overlay={'/senderOnboarding/' + id + '/edit'}><PencilIcon/></LinkToOverlay>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field name='Created'>{dateCreated}</Field>
                    <Field name='Name'>{name}</Field>
                    <Field name='Iban'>{iban}</Field>
                    <Field name='Vat'>{vatNumber}</Field>
                    <Field name='Address'>{address}</Field>
                    <Field name='E-mail'>{email}</Field>
                    <Field name='Language'>{language}</Field>
                    <div style={{wordBreak: 'break-all'}}>
                        <Field name='Paylink'>{paylink}</Field>
                    </div>
                    <Field name='Paylink document id'>
                        {paylinkDocumentId && (
                            <Link
                                to={`/document?id=${encodeURIComponent(paylinkDocumentId)}`}
                                children={paylinkDocumentId}
                            />
                        )}
                    </Field>
                    <Field name='Paylink status'>{paylinkStatus}</Field>
                    <Field name='Paylink date'>{paylinkDate}</Field>
                    <Field name='Paylink Iban'>{paylinkIban}</Field>
                    <Field name='Request Id'>{requestId}</Field>
                    <Field name='Onboarding partner'>{onboardingPartner}</Field>
                    <Table
                        name='Settings'
                        columns={{
                            key: 'Key',
                            value: 'Value',
                        }}
                        items={settingsFormatted}
                        extraClassName='table-sm'
                    />
                </Col>
                <Col>
                    <Field name='Contact person'>{contactPerson}</Field>
                    <Field name='Buckaroo onboarding link'>
                        {connectLink && (
                            <a href={connectLink} target="_blank" rel="noopener noreferrer">{connectLink}</a>
                        )}
                    </Field>
                    <LinkToOverlay overlay={`/senderOnboarding/${id}/buckaroo-mail`}>
                        <Button
                            children="Send buckaroo e-mail"
                            color="success"
                            size="sm"
                            className="mb-3"
                        />
                    </LinkToOverlay>
                    <TableJSON
                        name={(
                            <span>Buckaroo onboarding API data:&nbsp;
                                <LinkToOverlay
                                    overlay={`/senderOnboarding/${id}/edit-buckaroo-onboarding-api-data`}
                                >
                                    <PencilIcon/>
                                </LinkToOverlay>
                            </span>
                        )}
                        json={buckarooOnboardingAPIData}
                        extraClassName='table-sm'
                        hideLabelColon
                        expandable
                        expandableHeight={200}
                    />
                    <LinkToOverlay overlay={`/senderOnboarding/${id}/send-buckaroo-onboarding-merchant-request`}>
                        <Button
                            children="Send buckaroo onboarding merchant request"
                            color="success"
                            size="sm"
                            className="mb-3"
                        />
                    </LinkToOverlay>
                    <Field name="Buckaroo Onboarding Reference">{buckarooOnboardingReference}</Field>
                    <Table
                        name='Buckaroo expected documents to complete'
                        columns={{
                            documentType: 'documentType',
                            documentDescription: 'documentDescription',
                            requestStatus: {name: 'Last status', style: {width: 100}},
                            sendLink: {name: '', style: {width: 150}},
                        }}
                        items={buckarooDocumentsToComplete.map(document => ({
                            ...document,
                            key: document.id,
                            sendLink: (
                                <LinkToOverlay
                                    overlay={`/senderOnboarding/${id}/send-buckaroo-file/${document.id}`}
                                    children="Send to Buckaroo"
                                />
                            ),
                        }))}
                        extraClassName='table-sm'
                    />
                    <Table
                        name={(
                            <span>Buckaroo files:&nbsp;
                                <LinkToOverlay
                                    overlay={`/senderOnboarding/${id}/add-buckaroo-file`}
                                >
                                    <PlusIcon/>
                                </LinkToOverlay>
                            </span>
                        )}
                        columns={{
                            dateCreated: 'Date',
                            download: 'Download',
                            sizeFormatted: 'Size',
                        }}
                        items={buckarooFiles.map(buckarooFile => ({
                            ...buckarooFile,
                            key: buckarooFile.id,
                            sizeFormatted: buckarooFile.size && prettyBytes(buckarooFile.size),
                            download: (
                                //eslint-disable-next-line
                                <a
                                    href="#"
                                    children={buckarooFile.name}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await downloadBuckarooFile(buckarooFile.id);
                                    }}
                                />
                            ),
                        }))}
                        extraClassName='table-sm'
                        hideLabelColon
                    />
                    <Field name='Buckaroo finalized'>{buckarooFinalized}</Field>
                    <LinkToOverlay overlay={`/senderOnboarding/${id}/complete-buckaroo-onboarding-merchant-request`}>
                        <Button
                            children="Finalize buckaroo onboarding merchant request"
                            color="success"
                            size="sm"
                            className="mb-3"
                        />
                    </LinkToOverlay>
                    <Table
                        name='Buckaroo requests log'
                        columns={{
                            dateCreated: 'Date',
                            description: 'Description',
                            responseStatusCode: 'Response Status Code',
                            pllayUser: 'User',
                        }}
                        items={buckarooRequests.map(buckarooRequest => ({
                            ...buckarooRequest,
                            key: buckarooRequest.id,
                            responseStatusCode: (
                                <LinkToOverlay
                                    overlay={`/buckaroo-request/${buckarooRequest.id}`}
                                >
                                    {buckarooRequest.responseStatusCode}
                                </LinkToOverlay>
                            ),
                        }))}
                        extraClassName='table-sm'
                    />
                </Col>
            </Row>
        </>
    );
};

export default SenderOnboardingDetail;
