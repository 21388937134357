import React from 'react';

const LoginGoogle = () => {
    const googleSignUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/google/grant-code&response_type=code&client_id=${process.env.REACT_APP_CLOUD_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid&access_type=offline&state=${encodeURIComponent(window.location.href.split(window.location.origin)[1])}`;

    return (
        <div>
            <a className="btn btn-lg btn-success btn-block"
               href={googleSignUrl}>
                Sign in with Google
            </a>
        </div>
    );
};
export default LoginGoogle;
