import RavenJS from 'raven-js';
import queryString from 'query-string';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const _pomFetch = (method, path, body, logError = true, skipJsonHeaders = false) => {
    return fetch(backendUrl + path, {
        method: method,
        headers: Object.assign(
            {
                'X-Authentication': sessionStorage.authToken
            },
            skipJsonHeaders ? null : {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
        ),
        body: body,
    }).then(response => {
        if (response.status === 200) {
            return response.json();
        } else {
            return response.text().then(text => {
                try {
                    const data = JSON.parse(text);
                    if (data.message) {
                        return Promise.reject(data.message);
                    }
                    if (data[0].message) {
                        return Promise.reject(data[0].message);
                    }
                } catch (err) {
                }

                return Promise.reject(response.status + ': ' + response.statusText);
            });
        }
    }).catch(errorMessage => {
        if (logError) {
            RavenJS.captureException(new Error(errorMessage));
        }
        return Promise.reject(errorMessage);
    });
};

export function editUser(id, email, pomPaymentsStatus, pomBusinessStatus, pomPortalStatus, language, twoFaStatus) {
    const body = JSON.stringify({
        action: 'editUser',
        email: email,
        pomPaymentsStatus: pomPaymentsStatus,
        pomBusinessStatus: pomBusinessStatus,
        pomPortalStatus: pomPortalStatus,
        twoFaStatus: twoFaStatus,
        language: language,
    });
    return _pomFetch('POST', '/backOffice/user/' + id, body);
}

export function disableUser(id) {
    const body = JSON.stringify({
        action: 'disableUser',
    });

    return _pomFetch('POST', '/backOffice/user/' + id, body);
}

export function resetPassword(id) {
    const body = JSON.stringify({
        action: 'resetPassword',
    });

    return _pomFetch('POST', '/backOffice/user/' + id, body);
}

export function reset2Fa(id) {
    const body = JSON.stringify({
        action: 'reset2Fa',
    });

    return _pomFetch('POST', '/backOffice/user/' + id, body);
}

export function requestMagicLink(id) {
    const body = JSON.stringify({
        action: 'requestMagicLink',
    });

    return _pomFetch('POST', '/backOffice/user/' + id, body);
}

export function emailVerificationLink(id) {
    const body = JSON.stringify({
        action: 'emailVerificationLink',
    });

    return _pomFetch('POST', '/backOffice/user/' + id, body);
}

export function disableSenderUser(userId) {
    return _pomFetch('POST', '/backOffice/users/disableSenderUser/' + userId, null);
}

export function fetchUsers(searchParams) {
    return _pomFetch('GET', '/backOffice/users?' + queryString.stringify(searchParams), null);
}

export function fetchUser(id) {
    return _pomFetch('GET', '/backOffice/user/' + id, null);
}

export function fetchAllRoles() {
    return _pomFetch('GET', '/backOffice/roles', null);
}

export function fetchApplicationInfo() {
    return _pomFetch('GET', '/backOffice/applicationInfo/', null);
}

export function fetchParentSenderRoles(senderId) {
    return _pomFetch('GET', `/backOffice/sender/${senderId}/roles`, null);
}

export function fetchUserRoles(userId) {
    return _pomFetch('GET', '/backOffice/user/' + userId + '/roles', null);
}

export function editRoles(userId, roles) {
    const body = JSON.stringify({
        action: 'editRoles',
        roles: roles,
    });
    return _pomFetch('POST', '/backOffice/user/' + userId, body);
}

export function fetchSenderOnboardings(searchParams) {
    return _pomFetch('GET', '/backOffice/senderOnboarding?' + queryString.stringify(searchParams), null);
}

export function fetchSenderOnboarding(id) {
    return _pomFetch('GET', '/backOffice/senderOnboarding/' + id, null);
}

export function fetchDocuments(searchParams) {
    return _pomFetch('GET', '/backOffice/documents?' + queryString.stringify(searchParams), null);
}

export function getDocumentDownloadUrl(id) {
    return backendUrl + '/backOffice/pdf/' + id + '?authToken=' + sessionStorage.authToken;
}

export function getMessageDownloadUrl(id) {
    return backendUrl + '/backOffice/message/pdf/' + id + '?authToken=' + sessionStorage.authToken;
}

export function fetchDocument(id) {
    return _pomFetch('GET', '/backOffice/document/' + id, null);
}

export function fetchDocumentJson(id) {
    return _pomFetch('GET', '/backOffice/documentJson/' + id, null);
}

export function fetchTasks(searchParams) {
    return _pomFetch('GET', '/backOffice/tasks?' + queryString.stringify(searchParams), null);
}

export function fetchTaskNamesSelectOptions() {
    return _pomFetch('GET', '/backOffice/taskNames', null);
}

export function fetchDocRefs(searchParams) {
    return _pomFetch('GET', '/backOffice/docRefs?' + queryString.stringify(searchParams), null);
}

export function downloadDocRefFile(messageId) {
    return backendUrl + '/backOffice/docRef/' + messageId + '?authToken=' + sessionStorage.authToken;
}

export function fetchTaskDocumentIdsNotReported(taskId) {
    return _pomFetch('GET', `/backOffice/documentIdsNotReported/${taskId}`, null)
}

export function deliveryReportFile(taskId) {
    return backendUrl + '/backOffice/deliveryReportFile/' + taskId + '?authToken=' + sessionStorage.authToken;
}

export function fetchTasklog(id, searchParams) {
    return _pomFetch('GET', '/backOffice/tasklog/' + id + '?' + queryString.stringify(searchParams), null);
}

export function unlockTask(id) {
    return _pomFetch('POST', '/backOffice/tasks/' + id + '/unlock', null);
}

export function restartTask(id) {
    return _pomFetch('POST', '/backOffice/tasks/' + id + '/restart', null);
}

export function downloadTasksUrl() {
    return backendUrl + '/backOffice/tasks';
}

export function fetchTask(id) {
    return _pomFetch('GET', '/backOffice/task/' + id, null);
}

export function fetchTaskLog(taskId) {
    return _pomFetch('GET', `/backOffice/docRefLog/${taskId}`, null);
}

export function fetchPaypageOptinsUrl(searchParams) {
    return _pomFetch('GET', '/backOffice/paypageOptins?' + queryString.stringify(searchParams), null);
}

export function downloadPaypageOptinsUrl() {
    return backendUrl + '/backOffice/paypageOptins';
}

export function fetchEmailFallBackSubscriptions(searchParams) {
    return _pomFetch('GET', '/backOffice/emailFallBackSubscriptions?' + queryString.stringify(searchParams), null);
}

export function checkEmailBlacklist(email) {
    return _pomFetch('POST', '/backOffice/email-blacklist', JSON.stringify({email: email}))
}

export function deleteBlacklistedEmail(email) {
    return _pomFetch('DELETE', '/backOffice/email-blacklist', JSON.stringify({email: email}))
}

export function downloadEmailFallBackSubscriptionsUrl() {
    return backendUrl + '/backOffice/emailFallBackSubscriptions';
}

export function fetchAuditTrailSupportedTypes() {
    return _pomFetch('GET', '/backOffice/auditTrails/supportedTypes', null);
}

export function fetchAuditTrails(searchParams) {
    return _pomFetch('GET', '/backOffice/auditTrails?' + queryString.stringify(searchParams), null);
}

export function downloadAuditTrails() {
    return backendUrl + '/backOffice/auditTrails';
}

export function subscribeUnsubscribe(token) {
    return _pomFetch('POST', '/backOffice/subscribeUnsubscribe/' + token, null);
}

export function fetchSenders(searchParams) {
    return _pomFetch('GET', '/backOffice/senders?' + queryString.stringify(searchParams), null);
}

export function fetchSendersList(searchParams) {
    return _pomFetch('GET', '/backOffice/sendersList?' + queryString.stringify(searchParams), null);
}

export function uploadLogoToS3(logo) {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/backOfficeCreateSender/uploadLogoToS3', {
        method: 'POST',
        headers: {
            'X-Authentication': sessionStorage.authToken
        },
        body: logo,
    }).then(response => {
        return response.json();
    })
        .catch(errorMessage => {
            return Promise.reject(errorMessage);
        });
}

export function downloadUserCredentials(senderCredentials) {
    let awsCredentials = senderCredentials.awsbucket ? `\nawsBucket: ${senderCredentials.awsbucket}\nawsUsername: ${senderCredentials.awsUsername}\nawsPassword: ${senderCredentials.awsPassword}` : '';

    const csvHeaders = ["url", "username", "password", "extra", "name", "grouping", "fav", "totp"];
    const url = process.env.REACT_APP_POM_ENV === 'prod' ? "https://pom.be" : "https://beta.pom.be"
    const namePrefix = process.env.REACT_APP_POM_ENV === 'prod' ? "[P]" : "[B]"
    const csvData = [
        url,
        senderCredentials.username,
        senderCredentials.password,
        `"senderId: ${senderCredentials.senderId + awsCredentials}"`,
        `"${namePrefix} ${senderCredentials.contractNumber}: ${senderCredentials.name}"`,
        "Import",
        "",
        ""
    ];

    const csvContent = [csvHeaders.join(','), csvData.join(',')].join('\n');
    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});

    sendFileToBrowser(`${senderCredentials.name}.csv`, blob);
}

export function fetchSenderTemplatesList() {
    return _pomFetch('GET', '/backOffice/senderTemplatesList?', null);
}

export function fetchSenderTemplate(templateId) {
    return _pomFetch('GET', `/backOffice/senderTemplate/${templateId}`, null);
}

export function deleteSenderTemplate(templateId) {
    return _pomFetch('DELETE', `/backOffice/senderTemplate/${templateId}/delete`, null);
}


export function fetchSendersListForPaylink() {
    return _pomFetch('GET', '/backOffice/sendersListForPaylink', null);
}

export function refreshSendersListForPaylink() {
    return _pomFetch('POST', '/backOffice/sendersListForPaylink', null);
}

export function fetchPartnerSettingsForSender(senderId) {
    return _pomFetch('GET', '/backOffice/createSender/partnerSettings?' + queryString.stringify({senderId: senderId}));
}

export function fetchMerchantsForSender(senderId) {
    return _pomFetch('GET', '/backOffice/createSender/merchants?' + queryString.stringify({senderId: senderId}));
}

export function fetchPaymentProvidersList() {
    return _pomFetch('GET', '/backOffice/paymentMethodsList');
}

export function fetchSender(id) {
    return _pomFetch('GET', '/backOffice/sender/' + id, null);
}

export async function fetchSenderForEdit(id) {
    const senderPromise = _pomFetch('GET', '/backOffice/sender/' + id, null);
    const countriesPromise = _pomFetch('GET', '/backOffice/countries', null);
    const [sender, countries] = await Promise.all([senderPromise, countriesPromise]);

    return {
        sender,
        countries,
    };
}

export function editMerchant(merchantId, enabled, payoutsEnabled, attrs, senderId) {
    const body = JSON.stringify({
        action: 'editMerchant',
        enabled: enabled,
        payoutsEnabled: payoutsEnabled,
        attrs: attrs,
        senderId: senderId,
    });
    return _pomFetch('POST', '/backOffice/merchant/' + merchantId, body);
}

export function addMerchant(senderId, paymentProviderType) {
    const body = JSON.stringify({
        paymentProviderType: paymentProviderType,
    });
    return _pomFetch('POST', '/backOffice/addMerchant/' + senderId, body);
}

export function editSender(senderId, params) {
    const body = JSON.stringify(params);
    return _pomFetch('POST', '/backOffice/sender/' + senderId, body);
}

export function editTransaction(transactionId, params) {
    const body = JSON.stringify(params);
    return _pomFetch('PUT', '/backOffice/transaction/' + transactionId, body);
}

export function editPartnerSettings(partnerId, partnerSettings) {
    const body = JSON.stringify({
        action: 'editPartnerSettings',
        partnerSettings: partnerSettings,
    });
    return _pomFetch('POST', '/backOffice/partner/' + partnerId, body);
}

export function fetchTransactions(searchParams) {
    return _pomFetch('GET', '/backOffice/transactions?' + queryString.stringify(searchParams), null);
}

export function fetchTransaction(id) {
    return _pomFetch('GET', '/backOffice/transaction/' + id, null);
}

export function fetchTasksByTransactionId(id) {
    return _pomFetch('GET', `/backOffice/transaction/${id}/tasks`, null);
}

export function _pomFetchRetry(method, path, body, n = 3) {
    return _pomFetch(method, path, body, false).catch(error => {
        if (n === 0) {
            RavenJS.captureException(new Error(error));
            return Promise.reject(error);
        }
        return _pomFetchRetry(method, path, body, n - 1);
    });
}

export function fetchStats(type, options, downloadFile) {
    const body = JSON.stringify({
        type: type,
        options: options,
    });

    if (downloadFile) {
        return fetch(backendUrl + '/backOffice/stats', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'X-Authentication': sessionStorage.authToken
            },
            body: body,
        });
    } else {
        //Backend might run into database deadlocks when populating all stats. Try fetching 3 times for this reason.
        return _pomFetchRetry('POST', '/backOffice/stats', body);
    }
}

export function fetchBillingEvents(searchParams) {
    return _pomFetch('GET', '/backOffice/billingEvent?' + queryString.stringify(searchParams), null);
}

export function fetchBillingRules(searchParams) {
    return _pomFetch('GET', '/backOffice/billingRule?' + queryString.stringify(searchParams), null);
}

export function addBillingRule(rule) {
    const body = JSON.stringify(rule);
    return _pomFetch('POST', '/backOffice/billingRule', body);
}

export function editBillingRule(ruleId, rule) {
    const body = JSON.stringify(rule);
    return _pomFetch('PUT', '/backOffice/billingRule/' + ruleId, body);
}

export function deleteBillingRule(ruleId) {
    return _pomFetch('DELETE', '/backOffice/billingRule/' + ruleId, null);
}

export function addBillingContact(billingSenderId, params) {
    const body = JSON.stringify(params);
    return _pomFetch('POST', '/backOffice/billingContact/' + billingSenderId, body);
}

export function editBillingContact(contactId, params) {
    const body = JSON.stringify({...params});
    return _pomFetch('PUT', `/backOffice/billingContact/${contactId}/edit`, body);
}

export function fetchInvoices(searchParams) {
    return _pomFetch('GET', '/backOffice/invoice?' + queryString.stringify(searchParams), null);
}

export function fetchInvoice(invoiceId) {
    return _pomFetch('GET', '/backOffice/invoice/' + invoiceId, null);
}

export function sendInvoiceToEF(invoiceId) {
    return _pomFetch('POST', `/backOffice/invoice/${invoiceId}/send`, null);
}

export function editInvoice(invoiceId, reference, locked) {
    const body = JSON.stringify({
        id: invoiceId,
        reference: reference,
        locked: locked,
    });
    return _pomFetch('PUT', `/backOffice/invoice/${invoiceId}`, body);
}

export function deleteInvoice(invoiceId) {
    return _pomFetch('DELETE', '/backOffice/invoice/' + invoiceId, null);
}

export function createInvoice(keys) {
    const body = JSON.stringify({
        billableEventsBuckets: keys,
    });
    return _pomFetch('POST', '/backOffice/invoice', body);
}

export function fetchBillingCreditsList(searchParams) {
    return _pomFetch('GET', '/backOffice/billingCredits?' + queryString.stringify(searchParams));
}

export function fetchBillingCredits(id) {
    return _pomFetch('GET', `/backOffice/billingCredits/${id}`);
}

export function addBillingCredit(credit) {
    const body = JSON.stringify(credit);
    return _pomFetch('POST', '/backOffice/billingCredits', body);
}

export function fetchBillingCreditsPurchase(id) {
    return _pomFetch('GET', `/backOffice/billingCreditsPurchase/${id}`);
}

export function editBillingCreditsPurchase(billingCreditsPurchaseId, billingCreditsPurchase) {
    const body = JSON.stringify(billingCreditsPurchase);
    return _pomFetch('PUT', '/backOffice/billingCreditsPurchase/' + billingCreditsPurchaseId, body);
}

export function deleteBillingCreditsPurchase(id) {
    return _pomFetch('DELETE', `/backOffice/billingCreditsPurchase/${id}`, null);
}

export function fetchBillingSenders(searchParams) {
    return _pomFetch('GET', '/backOffice/billingSender?' + queryString.stringify(searchParams), null);
}

export function fetchBillingSender(id) {
    return _pomFetch('GET', `/backOffice/billingSender/${id}`);
}

export function synchroniseSalesFlare(id) {
    return _pomFetch('PUT', `/backOffice/billingSender/${id}/synchroniseSalesFlare`, {});
}

export function addBillingSender(state) {
    const body = JSON.stringify(state);
    return _pomFetch('POST', '/backOffice/billingSender', body);
}

export function editBillingSender(billingSenderId, state) {
    const body = JSON.stringify({
        id: billingSenderId,
        ...state,
    });
    return _pomFetch('PUT', '/backOffice/billingSender/' + billingSenderId, body);
}

export function deleteBillingSender(billingSenderId) {
    return _pomFetch('DELETE', '/backOffice/billingSender/' + billingSenderId);
}

export function downloadBillingResellersUrl() {
    return backendUrl + '/backOffice/billingReseller';
}

export function fetchBillingResellers(searchParams) {
    return _pomFetch('GET', '/backOffice/billingReseller?' + queryString.stringify(searchParams), null);
}

export function fetchBillingReseller(id) {
    return _pomFetch('GET', `/backOffice/billingReseller/${id}`);
}

export function addBillingReseller(state) {
    const body = JSON.stringify(state);
    return _pomFetch('POST', '/backOffice/billingReseller', body);
}

export function createSender(state) {
    const body = JSON.stringify(state);
    return _pomFetch('POST', '/backOffice/createSender', body);
}

export function saveSenderTemplate(state) {
    const body = JSON.stringify(state);
    return _pomFetch('POST', '/backOffice/saveSenderTemplate', body);
}

export function duplicateSenderTemplate(templateId) {
    return _pomFetch('POST', `/backOffice/senderTemplate/${templateId}/duplicate`, null);
}

export function createPaylinkSenderAndUpdateScanSender(state) {
    const body = JSON.stringify(state);
    return _pomFetch('POST', '/backOffice/createPaylinkSenderAndUpdateScanSender', body);
}

export function editBillingReseller(billingResellerId, state) {
    const body = JSON.stringify(state);
    return _pomFetch('PUT', '/backOffice/billingReseller/' + billingResellerId, body);
}

export function deleteBillingReseller(billingResellerId) {
    return _pomFetch('DELETE', '/backOffice/billingReseller/' + billingResellerId);
}

export function fetchBillingResellersList() {
    return _pomFetch('GET', '/backOffice/billingReseller/list');
}

export function fetchBillingReports(searchParams) {
    return _pomFetch('GET', '/backOffice/reports?' + queryString.stringify(searchParams), null);
}

export function fetchBillingReport(id) {
    return _pomFetch('GET', '/backOffice/reports/' + id, null);
}

export function downloadBillingReportUrl(id, type, download = false) {
    return `${backendUrl}/backOffice/reports/${id}/download/${type}?authToken=${sessionStorage.authToken}&download=${download}`;
}

export function addBillingReport(state) {
    const body = JSON.stringify(state);
    return _pomFetch('POST', '/backOffice/reports', body);
}

export function sendOnetimeCredentialsUrl(credentialsData) {
    const body = JSON.stringify(credentialsData);
    return _pomFetch('POST', `/backOffice/sendOnetimeCredentialsUrl`, body);
}

export function sendBillingReport(id, state) {
    const body = JSON.stringify(state);
    return _pomFetch('POST', `/backOffice/reports/${id}/send`, body);
}

export function fetchStatus() {
    return _pomFetch('GET', '/backOffice/billing/status');
}

export function cancelTransaction(id) {
    return _pomFetch('PUT', `/backOffice/transaction/${id}/cancel`)
}

export function runScript(scriptName, parameters) {
    return fetch(backendUrl + '/backOffice/scripts', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'X-Authentication': sessionStorage.authToken
        },
        body: JSON.stringify({
            scriptName: scriptName,
            parameters: parameters,
        }),
    });
}

export function retrieveFilenameFromHeaders(headers, defaultValue = '') {
    const header = headers.get('content-disposition');
    if (header) {
        const headerMatch = header.match(/filename=['"](.*)['"]/);
        if (headerMatch) {
            return headerMatch[1];
        }
    }

    return defaultValue;
}

export function validEmail(email) {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
}

export function validAmount(amount, documentType) {
    if (documentType === 'Contribution' && amount === 0) {
        return true;
    }
    return (amount);
}

export function delay(t) {
    return new Promise(resolve => setTimeout(resolve, t));
}

export function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = event => resolve(event.target.result);
        reader.onerror = () => reject();

        reader.readAsDataURL(file);
    });
}

export function fetchBillingEventSubtypes() {
    return _pomFetch('GET', '/backOffice/billingEventSubtypesList');
}

export function fetchBillingEventTypes() {
    return _pomFetch('GET', '/backOffice/billingEventTypesList');
}

export function fetchChannelList() {
    return _pomFetch('GET', '/backOffice/channelList');
}

export function fetchReportingIds() {
    return _pomFetch('GET', '/backOffice/reportingIds');
}

export function formatDate(date) {
    let day = '' + date.getDate();
    let month = '' + (date.getMonth() + 1);
    const year = date.getFullYear();

    if (day.length < 2)
        day = '0' + day;
    if (month.length < 2)
        month = '0' + month;

    return [year, month, day].join('-');
}

export function formatCurrency(amount, minimumFractionDigits = 2, maximumFractionDigits = 2) {
    if (amount === null || amount === undefined) {
        return '';
    }

    return `€ ${amount.toLocaleString('nl-BE', {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
    })}`;
}

export function formatNumber(number) {
    if (number === null || number === undefined) {
        return '';
    }

    return number.toLocaleString('nl-BE', {maximumFractionDigits: 2});
}

export function validateNumber(newNumber, oldNumber, fractionDigits = 2) {
    if (newNumber === '' || newNumber.match(new RegExp('^[0-9]*(\\.[0-9]{0,' + fractionDigits + '})?$', 'g'))) {
        return newNumber;
    }

    return oldNumber;
}

export function sendFileToBrowser(filename, blob) {
    let element = document.getElementById('pom-browser-download-file-link');

    if (!element) {
        element = document.createElement('a');
        element.style.display = 'none';
        element.id = 'pom-browser-download-file-link';
        document.body.appendChild(element);
    }

    element.href = window.URL.createObjectURL(blob);
    element.download = filename;
    element.click();
}

export const startInvoiceDownload = async (invoiceId) => {
    return _pomFetch('POST', `/backOffice/invoice/${invoiceId}/export`);
};

export const startUsersDownload = async (searchParams) => {
    return _pomFetch('POST', `/backOffice/users/export${searchParams}`);
};

export const startTransctionsDownload = async (searchParams) => {
    return _pomFetch('POST', `/backOffice/transactions/export${searchParams}`);
};

export const startSendersDownload = async (searchParams) => {
    return _pomFetch('POST', `/backOffice/senders/export${searchParams}`);
};

export const startDocumentsDownload = async (searchParams) => {
    return _pomFetch('POST', `/backOffice/documents/export${searchParams}`);
};

export const startBillingSendersDownload = async (searchParams) => {
    return _pomFetch('POST', `/backOffice/billingSenders/export${searchParams}`);
};

export const exportStatus = async (exportId) => {
    return _pomFetch('GET', `/export/status?id=${exportId}`);
};

export const saveExport = async (exportId, reference, mailWhenComplete) => {
    return _pomFetch('POST', `/export/save?id=${exportId}&reference=${reference}&mailWhenComplete=${mailWhenComplete}`);
};

export const cancelExport = async (exportId) => {
    return _pomFetch('POST', `/export/cancel?id=${exportId}`);
};

export const exportDownloadUrl = (exportId) => {
    return `${backendUrl}/export/download?id=${exportId}&authToken=${sessionStorage.authToken}`;
};

export function fetchExports(searchParams) {
    return _pomFetch('GET', '/export/exports?' + queryString.stringify(searchParams));
}

export function fetchGlobalSettings() {
    return _pomFetch('GET', '/backOffice/global-settings', null);
}

export async function fetchMaintenance() {
    return _pomFetch('GET', '/backOffice/maintenanceMode', null);
}

export async function editMaintenance(enabled, from, until, markdownNL, markdownFR, markdownEN) {
    const body = JSON.stringify({
        enabled,
        from,
        until,
        markdownNL,
        markdownFR,
        markdownEN,
    }, null, 2);

    return _pomFetch('POST', '/backOffice/maintenanceModeSave', body);
}

export function editGlobalSettings(settings) {
    const body = JSON.stringify({
        settings: settings,
    });
    return _pomFetch('POST', '/backOffice/global-settings/update', body);
}

export function fetchSpeosArchiveBatches(searchParams) {
    return _pomFetch('GET', '/backOffice/speos/archive?' + queryString.stringify(searchParams));
}

export function fetchSpeosArchiveBatch(id) {
    return _pomFetch('GET', '/backOffice/speos/archive/' + id, null);
}

export function fetchSpeosReprintBatches(searchParams) {
    return _pomFetch('GET', '/backOffice/speos/reprint?' + queryString.stringify(searchParams));
}

export function fetchSpeosReprintBatch(id) {
    return _pomFetch('GET', '/backOffice/speos/reprint/' + id, null);
}

export function fetchMandate(id) {
    return _pomFetch('GET', '/backOffice/mandates/' + id, null);
}

export function fetchMandateTransactions(id) {
    return _pomFetch('GET', '/backOffice/mandates/' + id + '/transactions', null);
}

export function senderOnboardingBuckarooSendMail(id, language) {
    const body = {
        senderOnboardingId: id,
        language: language,
    };

    return _pomFetch('POST', `/backOfficeSenderOnboarding/sendBuckarooMail`, JSON.stringify(body));
}

export function editSenderOnboarding(id, body) {
    const usedBody = {
        ...body,
        senderOnboardingId: id,
    };

    return _pomFetch('POST', '/backOfficeSenderOnboarding/save', JSON.stringify(usedBody));
}

export function editSenderOnboardingData(id, key, data) {
    const body = {
        senderOnboardingId: id,
        key,
        data,
    };

    return _pomFetch('POST', '/backOfficeSenderOnboarding/saveData', JSON.stringify(body));
}

export function sendBuckarooOnboardingMerchantRequest(id) {
    const body = {
        senderOnboardingId: id,
    };

    return _pomFetch('POST', `/backOfficeSenderOnboarding/sendBuckarooOnboardingMerchantRequest`, JSON.stringify(body));
}

export function completeBuckarooOnboardingMerchantRequest(id) {
    const body = {
        senderOnboardingId: id,
    };

    return _pomFetch('POST', `/backOfficeSenderOnboarding/completeBuckarooOnboardingMerchantRequest`, JSON.stringify(body));
}

export function buckarooAddFile(id, file) {
    const formData = new FormData();
    formData.append('senderOnboardingId', id);
    formData.append('file', file);

    return _pomFetch('POST', '/backOfficeSenderOnboarding/buckarooAddFile', formData, true, true);
}

export function buckarooSendFile(documentId, fileId) {
    const body = {
        document: documentId,
        file: fileId,
    };

    return _pomFetch('POST', '/backOfficeSenderOnboarding/buckarooSendFile', JSON.stringify(body));
}

export async function downloadBuckarooFile(id) {
    const response = await fetch(backendUrl + '/backOfficeSenderOnboarding/buckarooFile/' + id, {
        headers: {
            'X-Authentication': sessionStorage.authToken
        },
    });

    if (response.status !== 200) {
        throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
    }

    let filename = retrieveFilenameFromHeaders(response.headers);
    const blob = await response.blob();
    sendFileToBrowser(filename, blob);
}

export function fetchBuckarooRequest(id) {
    return _pomFetch('GET', '/backOfficeSenderOnboarding/buckarooRequest/' + id, null);
}

export function uploadDocRef(senderId, body) {
    return fetch(backendUrl + '/backOffice/senders/' + senderId + '/docrefupload', {
        method: 'POST',
        headers: {
            'X-Authentication': sessionStorage.authToken,
        },
        body: body,
    }).catch(errorMessage => {
        return Promise.reject(errorMessage);
    });
}

export function mtpAddFile(month, file) {
    const formData = new FormData();
    formData.append('month', month);
    formData.append('file', file);

    return _pomFetch('POST', '/invoice/mtpAddFile', formData, true, true);
}

export function fetchMtpFiles(searchParams) {
    return _pomFetch('GET', '/invoice/mtpFiles?' + queryString.stringify(searchParams));
}

export async function mtpDownloadFile(id) {
    const response = await fetch(backendUrl + '/invoice/mtpFile/' + id, {
        headers: {
            'X-Authentication': sessionStorage.authToken
        },
    });

    if (response.status !== 200) {
        throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
    }

    let filename = retrieveFilenameFromHeaders(response.headers);
    const blob = await response.blob();
    sendFileToBrowser(filename, blob);
}

export function mtpDeleteFile(id) {
    return _pomFetch('DELETE', '/invoice/mtpDeleteFile/' + id, null);
}


