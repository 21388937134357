import React, {Component} from 'react';
import LoginTwoFa from "./Login/LoginTwoFa";
import LoginGoogle from "./Login/LoginGoogle";
import {authenticate} from "./Login/login.service";

class Login extends Component {

    constructor(props) {
        super(props);

        this.auth = this.auth.bind(this);
        this.validateTwoFaStatus = this.validateTwoFaStatus.bind(this);

        this.state = {
            error: '',
        };

        if (window.location.pathname === '/google/grant-code') {
            const params = new URLSearchParams(window.location.search);
            const token = params.get('code');

            this.auth({token: token}, 'google').then(r => {});
        }
    }

    async auth(partialBody, platform) {
        try {
            await authenticate(partialBody, platform)
            this.props.login();
        } catch (e) {
            this.setState({
                error: e
            })
        }
    }

    async validateTwoFaStatus(username, password) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/session/check-2fa-status`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                username: username,
                password: password,
                expiresIn: 60 * 60 * 12 * 365,
                platform: 'Web',
                appName: "BackOffice",
                environmentId: 'N/A',
            }),
        });

            const responseBody = await response.json();
        if (response.status === 200) {
            return responseBody;
        }

        this.setState({
            error: responseBody.message || 'Er ging iets mis!'
        })
    };

    render() {
        return (
            <div className="container pt-3">
                <div className="row">
                    <div className="col">
                    </div>
                    <div className="col-6">
                        <h2 className='mb-4'>Please sign in</h2>

                        <div className="">
                            {this.state.error &&
                                <div className="alert alert-danger alert-dismissible">
                                    {this.state.error}
                                    <button type="button" className="close" data-dismiss="alert"
                                            aria-label="Close"><span
                                        aria-hidden="true">&times;</span></button>
                                </div>
                            }

                            <LoginGoogle/>
                            <div className="dropdown-divider my-4"></div>
                            <LoginTwoFa authenticate={this.auth} validateTwoFaStatus={this.validateTwoFaStatus}/>
                        </div>

                    </div>
                    <div className="col">
                    </div>
                </div>
            </div>
        );
    }

}

export default Login;

