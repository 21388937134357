import React, {Component, Fragment} from 'react';
import {IssueOpenedIcon} from 'react-octicons';
import {
    disableSenderUser,
    disableUser,
    editUser,
    emailVerificationLink,
    requestMagicLink,
    reset2Fa,
    resetPassword
} from '../services';
import PropTypes from 'prop-types';
import FormGroupRowText from "../Elements/FormGroupRowText";
import FormGroupRowSelect from "../Elements/FormGroupRowSelect";
import {Col, Row} from "reactstrap";
import {withRefresh} from "../Elements/Fetch";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";
import {Role} from "../Auth/BackOfficeUserRole";

class UserEditForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: props.user.email,
            pomPaymentsStatus: props.user.pomPaymentsStatus,
            pomBusinessStatus: props.user.roles.includes('ROLE_QR_APP') ? 'enabled' : 'disabled',
            pomPortalStatus: props.user.roles.includes('ROLE_PORTAL') ? 'enabled' : 'disabled',
            twoFaStatus: props.user.twoFaStatus ? 'enabled' : 'disabled',
            language: props.user.language,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleAction(action) {
        switch (action) {
            case 'submit':
                return editUser(this.props.user.id, this.state.email, this.state.pomPaymentsStatus, this.state.pomBusinessStatus, this.state.pomPortalStatus, this.state.language, this.state.twoFaStatus).then(data => {
                    this.props.triggerRefresh(`user:${this.props.user.id}`);
                    this.props.onSubmit();
                    return data;
                });
            case 'disable':
                return disableUser(this.props.user.id).then(data => {
                    this.props.triggerRefresh(`user:${this.props.user.id}`);
                    this.props.onSubmit();
                    return data;
                });
            case 'resetPassword':
                return resetPassword(this.props.user.id).then(data => data.resetPasswordLink);
            case 'reset2Fa':
                return reset2Fa(this.props.user.id).then(data => data.twoFaActive ? '2FA is still active' : '2FA has been reset');
            case 'requestMagicLink':
                return requestMagicLink(this.props.user.id).then(data => data.magicLink);
            case 'emailVerificationLink':
                return emailVerificationLink(this.props.user.id).then(data => data.emailVerificationLink);
            case 'disableSenderUser':
                return disableSenderUser(this.props.user.id).then(data => data.disableSenderUser);
            default:
            //do nothing
        }
    }

    handleChange(value, attribute) {
        this.setState({
            [attribute]: value
        });
    }

    render() {

        const actions = [
            {
                action: 'disable',
                displayName: 'Disable',
                disabledReason: this.props.user.cannotDisableReason,
                confirmMessage: 'Are you sure you want to disable this user?',
            },
            {
                action: 'resetPassword',
                displayName: 'Reset password',
                disabledReason: this.props.user.cannotResetPasswordReason,
                showFeedback: true,
            },
            {
                action: 'reset2Fa',
                displayName: 'Reset 2FA',
                disabledReason: null,
                showFeedback: true,
            },
            {
                action: 'requestMagicLink',
                displayName: 'Magic link',
                disabledReason: this.props.user.cannotRequestMagicLinkReason,
                showFeedback: true,
            },
            {
                action: 'emailVerificationLink',
                displayName: 'E-mail verification link',
                disabledReason: this.props.user.cannotShowEmailVerificationLink,
                showFeedback: true,
            },
            {
                action: 'disableSenderUser',
                displayName: 'disable sender user',
                confirmMessage: 'Are you sure you want to disable this sender user?',
                showFeedback: true,
                roles: [Role.WRITE],
            }
        ];

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            User: {this.props.user.name}
                        </h1>
                    </Col>
                </Row>
                <FormWithActions actions={actions} disableSubmitReason={this.props.user.cannotEditReason}
                                 onAction={this.handleAction}>
                    <FormGroupRowText
                        label="E-mail"
                        placeholder="E-mail"
                        type="email"
                        value={this.state.email}
                        onChange={value => this.handleChange(value, 'email')}
                        description={
                            <Fragment>
                                {this.props.user.sign2PayStatuses &&
                                    <div className="form-text">
                                        <IssueOpenedIcon className="align-middle mr-1" style={{fill: 'red'}}/><span
                                        className="align-middle">This user has Sign2Pay transactions with the following statuses: {this.props.user.sign2PayStatuses}</span>
                                    </div>
                                }
                                {this.props.user.verifiedEmailaddress &&
                                    <div className="form-text">
                                        <IssueOpenedIcon className="align-middle mr-1" style={{fill: 'red'}}/><span
                                        className="align-middle">Changing this will remove the verified e-mail address from this account.</span>
                                    </div>
                                }
                            </Fragment>
                        }
                    />

                    <FormGroupRowSelect
                        label="Language"
                        value={this.state.language}
                        onChange={value => this.handleChange(value, 'language')}
                        options={[
                            {value: 'nl', label: 'Dutch'},
                            {value: 'fr', label: 'French'},
                            {value: 'en', label: 'English'},
                        ]}
                    />

                    <FormGroupRowSelect
                        label="Pom Payments"
                        value={this.state.pomPaymentsStatus}
                        onChange={value => this.handleChange(value, 'pomPaymentsStatus')}
                        options={[
                            {value: 'default', label: 'Default (Look at merchant config)'},
                            {value: 'disabled', label: 'Disable'},
                            {value: 'enabled', label: 'Enable (Override merchant config)'},
                        ]}
                    />

                    <FormGroupRowSelect
                        label="Pom Business App"
                        value={this.state.pomBusinessStatus}
                        onChange={value => this.handleChange(value, 'pomBusinessStatus')}
                        options={[
                            {value: 'disabled', label: 'Disable'},
                            {value: 'enabled', label: 'Enable'},
                        ]}
                        description="Disabling will invalidate all current app sessions"
                    />

                    <FormGroupRowSelect
                        label="Pom Portal"
                        value={this.state.pomPortalStatus}
                        onChange={value => this.handleChange(value, 'pomPortalStatus')}
                        options={[
                            {value: 'disabled', label: 'Disable'},
                            {value: 'enabled', label: 'Enable'},
                        ]}
                        description="Disabling will invalidate all current portal sessions"
                    />
                    <FormGroupRowSelect
                        label="2FA"
                        value={this.state.twoFaStatus}
                        onChange={value => this.handleChange(value, 'twoFaStatus')}
                        options={[
                            {value: 'disabled', label: 'Disable'},
                            {value: 'enabled', label: 'Enable'},
                        ]}
                        description="Enable or disable 2FA for this user"
                    />
                </FormWithActions>
            </Fragment>
        );
    }
}

UserEditForm.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        pomPaymentsStatus: PropTypes.string.isRequired,
        cannotDisableReason: PropTypes.string.isRequired,
        cannotResetPasswordReason: PropTypes.string.isRequired,
        cannotRequestMagicLinkReason: PropTypes.string.isRequired,
        cannotShowEmailVerificationLink: PropTypes.string.isRequired,
        cannotEditReason: PropTypes.string.isRequired,
        verifiedEmailaddress: PropTypes.bool.isRequired,
        sign2PayStatuses: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(UserEditForm);
